import React from "react"
import { Layout } from "../components/layout"

export const MdxPageLayout: React.FC = ({ children }) => {
  return (
    <Layout>
      <div className="mdx-content">{children}</div>
    </Layout>
  )
}

export default MdxPageLayout
