import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/website/website/src/templates/mdx-page.tsx";
import { graphql } from "gatsby";
import { SEO } from "../components/seo";
export const query = graphql`
  {
    iubendaDocument(type: { eq: "PrivacyPolicy" }) {
      html
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Privacy Policy" mdxType="SEO" />
    <div dangerouslySetInnerHTML={{
      __html: props.data.iubendaDocument.html
    }}></div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      