import { Link } from "gatsby"
import React from "react"
import { CofuAppIcon } from "./couf-app-icon"
import { Footer } from "./footer"

export const Layout: React.FC = ({ children }) => {
  return (
    <div className="page">
      <div className="page-content">
        <main className="container">
          <Link to="/">
            <CofuAppIcon />
          </Link>
          {children}
        </main>
      </div>

      <div className="page-footer">
        <Footer />
      </div>
    </div>
  )
}
